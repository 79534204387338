<template>
  <vs-dialog scroll overflow-hidden not-close v-model="this.active">
    <template #header>
      <h3>
        Buzon de sugerencias
      </h3>
    </template>

    <textarea
      class="textarea"
      rows="3"
      maxlength="1000"
      placeholder="Por favor, dinos que piensas ..."
      v-model="inputText"
    ></textarea>

    <template #footer>
      <div class="con-footer">
        <vs-button block success transparent @click="setSuggestion">
          Enviar
        </vs-button>
      </div>
    </template>
  </vs-dialog>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";

export default {
  name: "Suggestions",
  data: () => {
    return {
      active: true,
      inputText: ""
    };
  },
  methods: {
    async setSuggestion() {
      const body = {
        text: this.inputText,
        user_id: this.$store.state.currentUser.id
      };

      const apiClient = axios.create({
        baseURL: !window.location.host.includes("localhost")
          ? "https://api.bmyplan.com"
          : "http://localhost:8087" //
      });
      try {
        await apiClient.post("/suggestions", body);
        this.active = false;
        this.$router.push("/");
      } catch (error) {
        console.log(error);
      }
    }
  }
};
</script>

<style scoped>
.textarea {
  width: 100%;
}
</style>
